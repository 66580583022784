import { useEffect, useState } from 'react';

import { useUser } from '@hooks/user';

import MuiBackdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@components/core/Backdrop';
import { useRouter } from 'next/router';
import { Dialog, Iframe, DialogContent } from './styles';

const EditProfile: React.FC = () => {
  const { user, setUser, showEditProfile, setShowEditProfile } = useUser();
  const [isLoading] = useState(false);
  const [isLoadingIframe, setIsLoadingIframe] = useState(false);
  const router = useRouter();

  const editProfileURL = `${process.env.NEXT_PUBLIC_PROFILE}/frame/v4/onboarding?lng=${router.locale}&hide[0]=pains&hide[1]=mixed`;

  useEffect(() => {
    const handleMessages = message => {
      if (
        !(editProfileURL || '').includes(message.origin) ||
        (typeof message.data !== 'string' && !(message.data instanceof String))
      )
        return;

      const data = JSON.parse(message.data);

      if (data.action !== 'close') return;

      if (data?.user && data?.user?._id) {
        setUser({ ...user, ...data?.user });
      }
      setShowEditProfile(false);
    };

    window.addEventListener('message', handleMessages);

    return () => {
      window.removeEventListener('message', handleMessages);
    };
  }, [setShowEditProfile, setUser, user, showEditProfile, editProfileURL]);

  useEffect(() => {
    if (!showEditProfile) return;

    setIsLoadingIframe(true);
  }, [showEditProfile]);

  const onClose = () => {
    setShowEditProfile(false);
  };

  if (!user || !user._id || !editProfileURL) return null;

  if (isLoading)
    return (
      <MuiBackdrop open style={{ zIndex: 90 }}>
        <CircularProgress />
      </MuiBackdrop>
    );

  return (
    <Dialog
      open={showEditProfile}
      aria-labelledby="login"
      onClose={onClose}
      BackdropComponent={Backdrop}
    >
      <DialogContent dividers style={{ WebkitOverflowScrolling: 'touch' }}>
        {isLoadingIframe && (
          <CircularProgress style={{ position: 'absolute' }} />
        )}
        <Iframe
          src={editProfileURL}
          title="Startse profile modal"
          width="100%"
          height="100%"
          frameBorder="0"
          scrolling="yes"
          onLoad={() => setIsLoadingIframe(false)}
          style={{ opacity: isLoadingIframe ? '0' : '1' }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EditProfile;
